import React, {  } from "react";

import styled from "styled-components";

const Roulette = styled.div`
  width: 91.5vw;
  height: 91.5vw;
  position: relative;

  @media (min-width: 1024px) {
    width: 91.5vh;
    height: 91.5vh
  }
`;

const BoxPart = styled.div`
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  transform: rotate3d(0, 0, 0, 0deg);
  width: 100%;
`;

const BoxWrap = styled.div`
  background-size: 100% 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 5.5%;
`;

const PartList = styled.li`
  transform: rotate(${(props) => props.rotateItem}deg);
  left: ${(props) => props.rotatePrize.left}%;
  width: ${(props) => props.itemWidth}%;
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 50%;
  justify-content: flex-start;
  position: absolute;
  transform-origin: 50% 100%;

  &:before {
    margin-left: -1px;
    transform: rotate(-${(props) => props.rotateBefore}deg);

    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    background-image: linear-gradient(
      0deg,
      #ffcf00,
      #ff0 14%,
      #ff0 35%,
      #fff 42%,
      #ffc600 72%
    );
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    transition: width 0.3s, margin-left 0.3s;
    width: 2px;
    z-index: 1;
  }

  &:after {
    content: "${(props) => props.content}";

    font-family: Verdana, sans-serif;
    font-size: 4.5833333333vw;
    font-weight: 700;
    position: relative;
    text-shadow: -3px -4px 0 #e81fa1, -2px -4px 0 #e81fa1, -1px -4px 0 #e81fa1,
      0 -4px 0 #e81fa1, 1px -4px 0 #e81fa1, 2px -4px 0 #e81fa1,
      3px -4px 0 #e81fa1, 4px -4px 0 #e81fa1, 4px -3px 0 #e81fa1,
      4px -2px 0 #e81fa1, 4px -1px 0 #e81fa1, 4px 0 0 #e81fa1, 4px 1px 0 #e81fa1,
      4px 2px 0 #e81fa1, 4px 3px 0 #e81fa1, 4px 4px 0 #e81fa1, 3px 4px 0 #e81fa1,
      2px 4px 0 #e81fa1, 1px 4px 0 #e81fa1, 0 4px 0 #e81fa1, -1px 4px 0 #e81fa1,
      -2px 4px 0 #e81fa1, -3px 4px 0 #e81fa1, -4px 4px 0 #e81fa1,
      -4px 3px 0 #e81fa1, -4px 2px 0 #e81fa1, -4px 1px 0 #e81fa1,
      -4px 0 0 #e81fa1, -4px -1px 0 #e81fa1, -4px -2px 0 #e81fa1,
      -4px -3px 0 #e81fa1, -4px -4px 0 #e81fa1;
  }
`;

const PartAward = styled.span`
  padding-bottom: 18px;
  line-height: 1.2;
  padding-bottom: 1.25vw;
  position: relative;
  text-align: center;
  width: 70%;
  color: ${(props) => props.textColor}
`;

const PartColor = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:before {
    transform: rotate(${(props) => props.rotatePrize.rotate}deg)
      skew(${(props) => props.rotatePrize.skew}deg);
    left: -50%;
    transform-origin: 100% 100%;
    background-color: ${(props) => props.backgroundColor};
  }

  &:after {
    transform: rotate(-${(props) => props.rotatePrize.rotate}deg)
      skew(-${(props) => props.rotatePrize.skew}deg);
    left: 50%;
    transform-origin: 0 100%;
    background-color: ${(props) => props.backgroundColor};
  }

  &:before,
  &:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const DrawWheel = ({ data }) => {

    const listRotates = {
        1: { rotate: 90, skew:0, left: -98 },
        2: { rotate: 90, skew: 0, left: -24 },
        3: { rotate: 60.2, skew: 0.5, left: 0.5},
        4: { rotate: 45.2, skew: 0, left: 12.1 },
        5: { rotate: 54.2, skew: 18, left: 20.3 },
        6: { rotate: 60, skew: 30, left: 24.5 },
        7: { rotate: 64.4, skew: 38.8, left: 28.3 },
        8: { rotate: 67.5, skew: 45, left: 30.9 },
        9: { rotate: 70, skew: 50, left: 33.4 },
        10: { rotate: 74, skew: 56, left: 35 },
        11: { rotate: 74, skew: 58, left: 36 },
        12: { rotate: 75, skew: 60, left: 37 },
        13: { rotate: 77, skew: 63, left: 38 },
        14: { rotate: 77, skew: 64, left: 38.5 },
        15: { rotate: 78, skew: 66, left: 39 },
        16: { rotate: 78, skew: 66.8, left: 40 },
        17: { rotate: 79, skew: 68.5, left: 40 },
        18: { rotate: 80, skew: 70, left: 41 },
        19: { rotate: 83, skew: 73.5, left: 41 },
        20: { rotate: 84.5, skew: 75.5, left: 42 },
    };
  const length = data?.length;
    const rotatePrize = length < 15 ? listRotates[length] : listRotates[length];
    // const rotatePrize = length < 15 ? listRotates[length] : listRotates[20];

  return (
    <div className="flex justify-center">
      <Roulette id="spin">
        <BoxWrap>
          <BoxPart>
            {data?.map((prize, i) => {
              const rotateItem = (360 / length) * i;
              const itemWidth = parseFloat(296 / length);
              const rotateBefore = parseFloat(180 / length);
              const backgroundColor = prize?.backgroundColor;
              const color = prize?.color;
              const text = prize?.option;
              const iconUrl = prize?.iconUrl;

              return (
                <PartList
                  className="pb-[1%] md:pb-[2%] pt-[10px] md:pt-[5vh]"
                  key={i}
                  rotateItem={rotateItem}
                  rotatePrize={rotatePrize}
                  content=""
                  itemWidth={itemWidth}
                  rotateBefore={rotateBefore}
                >
                  <PartColor
                    className="part-color"
                    rotatePrize={rotatePrize}
                    backgroundColor={backgroundColor}
                  >
                    <div
                      style={{ backgroundColor: backgroundColor }}
                      className="absolute w-[50px] h-[50px] md:h-[100px] -top-2 left-1/2 -translate-x-1/2"
                    ></div>
                  </PartColor>
                  <PartAward className="font-semibold text-[10px] md:text-[16px] lg:text-[18px]" textColor={color}>
                    {text}
                  </PartAward>
                  <div className="max-h-[40%] max-w-[55%] relative px-3">
                    <img className="w-full h-full object-contain" src={iconUrl} />
                  </div>
                </PartList>
              );
            })}
          </BoxPart>
        </BoxWrap>
      </Roulette>
    </div>
  );
};

export default DrawWheel;
