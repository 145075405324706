import React from "react";
import CustomModal from "../Modal/CustomModal";
import { Button, Flex, Table } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CongratulationAnimation from "../Animation/CongratulationAnimation";

const SlotAwardModal = ({
  textColor,
  buttonColor,
  openModal,
  onClose,
  selectedPrize,
  players,
  onOk,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const columns = [
    {
      title: "",
      dataIndex: "order",
      key: "order",
      render: (order) => (
        <Flex
          align="center"
          justify="center"
          className="h-[30px] w-[30px] font-bold rounded-full"
          style={{
            color: `#${textColor}`,
            backgroundColor: `#${buttonColor}`,
          }}
        >
          {order}
        </Flex>
      ),
    },
    {
      title: `${t("text.code")}`,
      dataIndex: "codeDial",
      align: "center",
      key: "codeDial",
      render: (codeDial) => <span>{codeDial}</span>,
    },
    {
      title: `${t("text.name")}`,
      dataIndex: "playerName",
      align: "center",
      key: "playerName",
    },
  ];

  if (openModal) {
    return (
      <CustomModal classNames="animate__animated animate__tada md:!min-w-[490px] rounded-md">
        <CongratulationAnimation />

        <div
          className="relative rounded-tl-md rounded-tr-md px-[15px] py-[12px] font-semibold text-[18px] leading-6 text-center uppercase"
          style={{
            color: `#${textColor}`,
            backgroundColor: `#${buttonColor}`,
          }}
        >
          <span>{t("text.result_spin_lucky")}</span>
          <Button
            type="link"
            className="hover:!text-white absolute right-2 top-2 px-1 text-xl"
            style={{
              color: `#${textColor}`,
            }}
            onClick={onClose}
          >
            <CloseCircleOutlined />
          </Button>
        </div>
        <div
          className="text-center py-2 font-semibold bg-[#f2f2f2] border-b border-gray-300"
          style={{
            color: `#${buttonColor}`,
          }}
        >
          {selectedPrize?.label}
        </div>
        <div className="text-xl">
          <Table
            columns={columns}
            dataSource={(players || []).map((item, index) => ({
              ...item,
              key: item?.id,
              order: index + 1,
            }))}
            pagination={false}
          />
        </div>
        <div className="text-center mt-5">
          <Button
            type="primary"
            className="bg-[#40ab00]"
            icon={<CheckCircleOutlined />}
            onClick={onOk}
          >
            {t("button.save_result")}
          </Button>
        </div>
      </CustomModal>
    );
  }
};

export default SlotAwardModal;
