import React, { useState, useEffect } from "react";

import { message } from "antd";
import SelectGroup from "./SelectGroup";
import PrimaryButton from "./PrimaryButton";
import SlotAwardModal from "../Modal/SlotAwardModal";
import ConfirmModal from "../Modal/ConfirmModal";
import CodeWinner from "./CodeWinner";
import CellBox from "./CellBox";
import { useTranslation } from "react-i18next";
import ListAudio from "./ListAudio";
import { useNavigate } from "react-router";

import { getOrderDial, spinDial, savePlayers } from "../../services/http";

import { DIAL_TYPES } from "../../utils/constants";
import { sleep } from "../../utils/utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const SlotSpin = ({ configs }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    id,
    isNameEvent,
    generateKey,
    eventName,
    dialPrizeType,
    dialType,
    numberCell,
    isImagePrize,
    prizes,
    textColor,
    buttonColor,
    backgroundNumberColor,
  } = configs;
  const [cellTotal, setCellTotal] = useState(numberCell);
  const [customT, setCustomT] = useState([]);
  const [spinNumbers, setSpinNumbers] = useState([]);
  const [winnerPlayers, setWinnerPlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [isShowSpin, setIsShowSpin] = useState(true);
  const [openModalAgain, setOpenModalAgain] = useState(false);
  const [openModalSaveResult, setOpenModalSaveResult] = useState(false);
  const [openModalAddPlayer, setOpenModalAddPlayer] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isShowCell, setIsShowCell] = useState(true);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    SetO(cellTotal).then(() => {
      appendHTMLfortag();
    });
  }, [cellTotal]);

  const resetSlot = async () => {
    setIsShowCell(false);

    await sleep(100);
    setIsShowCell(true);
    await sleep(10);
  };

  useEffect(() => {
    startMachineWithCounter();
  }, [counter]);

  const startMachineWithCounter = async () => {
    if (counter > 0 && counter <= players?.length) {
      await resetSlot();
      const codeDialPlayer = players[counter - 1]?.codeDial;
      startMachine(codeDialPlayer, dialPrizeType == 2);
    } else if (counter > players?.length) {
      await sleep(1000);
      playAudio("mp3EndQuay");
      setSpinning(false);
      setOpenModal(true);
      setIsShowSpin(false);
    }
  };

  useEffect(() => {
    if (selectedPrize) {
      fetchOrderDial();
    }
  }, [selectedPrize?.key]);

  const fetchOrderDial = async () => {
    try {
      const { data } = await getOrderDial({
        key: selectedPrize?.key,
        refId: id,
      });

      setSpinNumbers(data);
    } catch (error) {
      message.error(error);
    }
  };

  const handleSaveResult = async () => {
    try {
      setLoadingSave(true);

      const response = await savePlayers(selectedOrder?.key, players);

      if (response?.code == 200 && !response?.isError) {
        message.success(response?.message);
        setOpenModalSaveResult(false);
        setOpenModal(false);
        await resetSlot();
      } else {
        message.error(response?.message);
      }

      setLoadingSave(false);
    } catch (error) {
      message.error(error);
    }
  };

  const handleSpinDial = async () => {
    if (spinning) {
      return;
    }

    setSpinning(true);

    try {
      const response = await spinDial({
        refId: id,
        prizeId: selectedOrder?.key,
        key: generateKey,
      });

      if (response?.code == 200 && !response?.isError) {
        setPlayers(response?.data);
        // const codeDialPlayer = response?.data?.length > 0 && response?.data[0]?.codeDial;

        // if (codeDialPlayer) {
        //   startMachine(codeDialPlayer, dialPrizeType == 2);
        // }
        if (response?.data?.length > 0) {
          setCounter((prev) => prev + 1);
        }
      } else if (response?.code == 404) {
        setOpenModalAddPlayer(true);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error);
      setSpinning(false);
    }
  };

  function playAudio(x, y) {
    if (y) {
      setInterval(function () {
        document.getElementById(x).pause();
        document.getElementById(x).currentTime = 240;
        document.getElementById(x).play();
      }, 50);
    } else {
      document.getElementById(x).currentTime = 0;
      document.getElementById(x).play();
    }
  }

  function pauseAudio(x) {
    document.getElementById(x).pause();
  }

  const shuffle = (array) => {
    let counter = array.length;
    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      let index = Math.floor(Math.random() * counter);
      // Decrease counter by 1
      counter--;

      // And swap the last element with it
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }
    return array;
  };

  const SetO = (x) => {
    return new Promise((resolve, reject) => {
      setCellTotal(x);
      resolve(true);
    });
  };

  const appendHTMLfortag = () => {
    const numberStringMap = {
      [DIAL_TYPES.NUMBER_WORD]:
        " |a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z|0|1|2|3|4|5|6|7|8|9",
      [DIAL_TYPES.NUMBER]: " |0|1|2|3|4|5|6|7|8|9",
      [DIAL_TYPES.WORD]:
        " |a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z",
    };
    const isAddZero =
      dialType == DIAL_TYPES.NUMBER_WORD || dialType == DIAL_TYPES.NUMBER;

    const generateNumbers = (numberString) => {
      let numbers = "~";
      for (let i = 0; i < 15; i++) {
        if (isAddZero) {
          numbers += "0,";
        }

        numbers += shuffle(numberString.split("|")).toString() + "~";
      }

      return numbers;
    };

    return new Promise((resolve, reject) => {
      let numberString = numberStringMap[dialType] || "";

      if (numberString) {
        const numbers = generateNumbers(numberString);
        setCustomT(numbers.split("~"));
        resolve(true);
      } else {
        reject(new Error("Invalid dial prize type"));
      }
    });
  };

  const createSlotMachine = (elementId, customTIndex, ketQua, duration) => {
    const element = document.getElementById(elementId);
    return new window.SlotMachine(element, {
      active: 0,
      spin: 1,
      delay: duration,
      randomize: function () {
        return customT[customTIndex]
          .split(",")
          .indexOf(
            ketQua.toLowerCase().substring(customTIndex - 1, customTIndex)
          );
      },
    });
  };

  const shuffleMachines = (machines, spinTimes) => {
    machines.forEach((machine) => machine.shuffle(spinTimes));
  };

  const stopMachines = (machines) => {
    machines.forEach((machine) => machine.stop());
  };

  const generateMachineIds = (count) => {
    return Array.from({ length: count }, (_, i) => `machine${i + 1}`);
  };

  const startMachine = (spinResult, isAll) => {
    const machineIds = generateMachineIds(cellTotal);

    let stopTime = 4000;
    let duration = isAll ? 500 : 800;
    let spinTimes = isAll ? 99999 : 3;

    const machines = machineIds.map((id, index) =>
      createSlotMachine(id, index + 1, spinResult, duration)
    );

    playAudio("mp3Spin");
    playAudio(isAll ? "mp3Quay" : "mp3QuayNgan");

    if (isAll) {
      shuffleMachines(machines, spinTimes);

      setTimeout(() => {
        stopMachines(machines);
        var sounds = document.getElementsByTagName("audio");
        for (let i = 0; i < sounds.length; i++) sounds[i].pause();

        pauseAudio("mp3Quay");
        // pauseAudio("mp3EndQuay");
        setTimeout(() => {
          // playAudio("mp3EndQuay");
          spinFinish();
        }, 1000);
      }, stopTime);
    } else {
      let daQuay = 0;

      const shuffleNextMachine = (index) => {
        if (index >= machines.length) return spinFinish();

        machines[index].shuffle(spinTimes, () => {
          daQuay++;
          if (daQuay < machines.length) {
            playAudio("mp3QuayNgan");
            shuffleNextMachine(index + 1);
          } else {
            spinFinish();
          }
        });
      };

      shuffleNextMachine(0);
    }
  };

  const spinFinish = async () => {
    const newPlayers = [...winnerPlayers, players[counter - 1]];

    setWinnerPlayers(newPlayers);
    await sleep(1000);
    setCounter((prev) => prev + 1);
  };

  const handleChangePrize = (prize) => {
    setSelectedPrize(prize);
    setSelectedOrder(null);
  };

  const handleChangeOrder = (order) => {
    setSelectedOrder(order);
  };

  const getItems = (items, defaultLabel) => {
    if (items?.length > 0) {
      return items;
    }

    return [
      {
        key: null,
        label: defaultLabel,
        prizeName: defaultLabel,
        orderDial: defaultLabel,
      },
    ];
  };

  return (
    <>
      <SlotAwardModal
        textColor={textColor}
        buttonColor={buttonColor}
        openModal={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        selectedPrize={selectedPrize}
        players={players}
        onOk={() => setOpenModalSaveResult(true)}
      />

      <ConfirmModal
        textOk={t("button.continue")}
        title={t("text.confirm_spin_again")}
        content={t("text.ok_spin_again")}
        openModal={openModalAgain}
        onClose={() => setOpenModalAgain(false)}
        onOk={() => window.location.reload()}
      />

      <ConfirmModal
        textOk={t("button.yes")}
        title={t("text.not_has_code")}
        content={t("text.confirm_redirect_add_player")}
        openModal={openModalAddPlayer}
        onClose={() => {
          setOpenModalAddPlayer(false);
          setSpinning(false);
        }}
        onOk={() =>
          window.location.replace(
            `${process.env.REACT_APP_CMS_URL}/events/QSNN/${id}?tab=3`
          )
        }
      />

      <ConfirmModal
        textOk={t("button.save")}
        title={t("text.save_result")}
        content={t("text.ok_save_result")}
        openModal={openModalSaveResult}
        onClose={() => setOpenModalSaveResult(false)}
        onOk={() => handleSaveResult()}
        loading={loadingSave}
      />

      <div className="w-full mx-auto pt-12">
        <div
          className="uppercase text-[33px] block px-[20px] mb-[10px] font-bold tracking-[4px] text-center h-[47px]"
          style={{
            textShadow:
              "rgba(255, 255, 255, 0.1) 0px 0px 1px, rgba(0, 0, 0, 0.59) 1px 1px 0px, rgba(28, 110, 164, 0) 1px -2px 4px",
            color: `#${textColor}`,
          }}
        >
          {isNameEvent ? eventName : ""}
        </div>

        <CellBox
          textColor={textColor}
          buttonColor={buttonColor}
          backgroundNumberColor={backgroundNumberColor}
          customT={customT}
          isShowCell={isShowCell}
          cellTotal={cellTotal}
        />

        <div className="flex flex-wrap justify-center md:justify-between mt-5 gap-3 max-w-[1366px] mx-auto px-5">
          <div className="min-w-[100px] max-w-[100px] md:min-w-[352px] md:max-w-[100px]">
            {isImagePrize && selectedOrder?.imageprize && (
              <img
                src={`${BASE_URL}/${selectedOrder?.imageprize}`}
                alt="prize"
              />
            )}
          </div>

          {players?.length > 0 && (
            <CodeWinner
              winnerPlayers={winnerPlayers}
              buttonColor={buttonColor}
            />
          )}

          <div>
            <div className="flex gap-3 flex-wrap">
              <SelectGroup
                textColor={textColor}
                buttonColor={buttonColor}
                selectedItem={selectedPrize}
                title={t("select.select_prize")}
                items={getItems(prizes, t("text.not_has_prize")).map(
                  ({ key, prizeName }) => ({
                    key: key,
                    label: prizeName,
                  })
                )}
                disabled={spinning || !isShowSpin}
                onChange={handleChangePrize}
              />

              <div className="min-w-[170px]">
                {selectedPrize && (
                  <SelectGroup
                    textColor={textColor}
                    buttonColor={configs?.buttonColor}
                    selectedItem={selectedOrder}
                    title={t("select.select_order")}
                    items={getItems(spinNumbers, t("text.not_has_order")).map(
                      ({ id, orderDial, imagePrize }) => ({
                        key: id,
                        label:
                          (spinNumbers?.length > 0
                            ? `${t("select.order_select")} `
                            : "") + orderDial,
                        imageprize: imagePrize,
                      })
                    )}
                    disabled={spinning || !isShowSpin}
                    onChange={handleChangeOrder}
                  />
                )}
              </div>
            </div>
            {selectedOrder && !isShowSpin && (
              <div className="flex justify-center mt-5 gap-3">
                <PrimaryButton
                  textColor={textColor}
                  buttonColor={buttonColor}
                  onClick={() => setOpenModalAgain(true)}
                >
                  {t("button.spin_again")}
                </PrimaryButton>
                <PrimaryButton
                  textColor={textColor}
                  buttonColor={buttonColor}
                  onClick={() => setOpenModal(true)}
                >
                  {t("button.result")}
                </PrimaryButton>
              </div>
            )}
            {selectedOrder && isShowSpin && (
              <div className="flex justify-center mt-5">
                <button
                  onClick={() => handleSpinDial()}
                  className="w-[100px] h-[100px] md:w-[145px] md:h-[145px] text-xl md:text-2xl font-semibold rounded-full border-[5px] border-red transform scale-100 transition-all duration-100 hover:brightness-125 hover:scale-105 uppercase"
                  style={{
                    color: `#${textColor}`,
                    backgroundColor: `#${buttonColor}`,
                  }}
                >
                  {t("button.spin")}
                </button>
              </div>
            )}
          </div>
        </div>
        <ListAudio />
      </div>
    </>
  );
};

export default SlotSpin;
