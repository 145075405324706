import React from "react";
import fanfareWinnerMp3 from "../../assets/mp3/fanfare-winner.mp3";
import smSpinMp3 from "../../assets/mp3/sm-spin.mp3";
import spinningSoundsMp3 from "../../assets/mp3//spinning-sounds.mp3";
import spinningMp3 from "../../assets/mp3//spinning.mp3";

const ListAutio = () => {
  return (
    <div className="hidden">
      <audio id="mp3Quay">
        <source src={spinningSoundsMp3} type="audio/mpeg" />
      </audio>
      <audio id="mp3EndQuay">
        <source src={fanfareWinnerMp3} type="audio/mpeg" />
      </audio>
      <audio id="mp3Spin">
        <source src={smSpinMp3} type="audio/mpeg" />
      </audio>
      <audio id="mp3QuayNgan">
        <source src={spinningMp3} type="audio/mpeg" />
      </audio>
    </div>
  );
};

export default ListAutio;
