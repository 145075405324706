import create from "zustand";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const useUserStore = create((set) => ({
  userInfo: null,

  setUserInfo: (data) => set({ userInfo: data }),

  clearUserInfo: () => set({ userInfo: null }),
}));

const useAwardModalStore = create((set) => ({
  open: false,

  setModal: (data) => set({ open: data }),
}));

const useInforUserFormStore = create((set) => ({
  open: false,

  setModal: (data) => set({ open: data }),
}));

const useSpinConfigsStore = create((set) => ({
  configs: null,

  setSpinConfigs: (data) =>
    set({
      configs: {
        ...data,
        desktopImageUrl: BASE_URL + "/" + data?.desktopImageUrl,
        mobileImageUrl: BASE_URL + "/" + data?.mobileImageUrl,
        spinImageUrl: BASE_URL + "/" + data?.spinImageUrl,
      },
    }),

  clearSpinConfigs: () => set({ configs: null }),
}));

const useWheelPrizesStore = create((set) => ({
  prizes: [],
  textColors: [],
  backgroundColors: [],

  setSpinPrizes: (data, GUI) => {
    let newPrizes = [];
    let newTextColors = [];
    let newBackgroundColors = [];

    if (Array.isArray(data)) {
      newPrizes = data.map((prize, index) => {
        const defaultBackground = index % 2 === 0 ? "#fa8375" : "#fff5c4";
        const defaultColor = index % 2 === 0 ? "#fff5c4" : "#fa8375";
        const color = prize?.textColor ? `#${prize.textColor}` : defaultColor;
        const backgroundColor = prize?.backgroundColor ? `#${prize.backgroundColor}` : defaultBackground;
        newTextColors.push(color);
        newBackgroundColors.push(backgroundColor);

        return {
          ...prize,
          id: prize?.id,
          option:
            prize?.prizeName?.length > 24
              ? prize?.prizeName?.substring(0, 24).trimEnd() + "..."
              : prize?.prizeName,
          image:
            prize.iconUrl && GUI === 1
              ? {
                  uri: BASE_URL + "/" + prize.iconUrl,
                  sizeMultiplier: 1.2,
                  offsetY: 120,
                  landscape: false,
                }
              : null,
          iconUrl: prize.iconUrl ? `${BASE_URL}/${prize.iconUrl}` : null,
          color: color,
          backgroundColor: backgroundColor,
          optionSize: 1,
        };
      });
    }

    return set({
      prizes: newPrizes,
      textColors: newTextColors,
      backgroundColors: newBackgroundColors,
    });
  },

  clearSpinPrizes: () => set({ prizes: [] }),
}));

const useWinPrizeStore = create((set) => ({
  winPrize: null,

  setWinPrize: (data) => set({ winPrize: data }),

  clearSpinConfigs: () => set({ winPrize: null }),
}));

const useConnectionSignalRStore = create((set) => ({
  connectionSignalR: null,

  setConnectionSignalR: (data) => set({ connectionSignalR: data }),

  clearConnectionSignalR: () => set({ connectionSignalR: null }),
}));

export {
  useUserStore,
  useAwardModalStore,
  useInforUserFormStore,
  useSpinConfigsStore,
  useWheelPrizesStore,
  useWinPrizeStore,
  useConnectionSignalRStore
};
