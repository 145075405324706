import React from "react";
import { useTranslation } from "react-i18next";

const CodeWinner = ({ winnerPlayers, buttonColor }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className="rounded-[8px] bg-[rgb(209,90,142)] text-center relative py-1 min-h-[100px]"
        style={{
          background: `#${buttonColor}`,
        }}
      >
        <div className="text-white pt-2 pb-3 px-4 h-[50px] border-b-[1px] border-[#f0f0f0] text-base font-semibold">
          {t("text.list_code_winner")}
        </div>
        <div className="overflow-y-auto max-h-[300px] overflow-x-hidden custom-scroll pt-1">
          {winnerPlayers.map((player, index) => (
            <div
              key={index}
              className={"winner-item slide-in text-white text-base py-1 mx-2"}
            >
              {player?.codeDial}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CodeWinner;
