import React from "react";

const PrimaryButton = ({ children, onClick, textColor, buttonColor }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      onClick={handleClick}
      className="w-auto min-w-[170px] px-4 h-[50px] font-bold rounded-[8px] border-[2px] border-red flex items-center justify-center gap-2 shadow-lg"
      style={{
        color: `#${textColor}`,
        backgroundColor: `#${buttonColor}`
      }}
    >
      {children && children}
    </button>
  );
};

export default PrimaryButton;
