import * as signalR from "@microsoft/signalr";

const createSignalRConnection = (hubUrl) => {
  const token = null;

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: token ? () => token : null,
    })
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();

  connection.onclose(() => {
    // console.log(`SignalR ${hubUrl} Disconnected`);
  });

  connection.onreconnecting(() => {
    // console.log(`SignalR ${hubUrl} Reconnecting`);
  });

  connection.onreconnected(() => {
    // console.log(`SignalR ${hubUrl} Reconnected`);
  });

  const startConnection = () => {
    return new Promise((resolve, reject) => {
      if (connection.state === signalR.HubConnectionState.Disconnected) {
        connection
          .start()
          .then(() => {
            resolve(connection);
          })
          .catch((err) => {
            console.error("Error connecting to SignalR:", err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  const disconnect = () => {
    if (connection.state === signalR.HubConnectionState.Connected) {
      connection.stop();
    }
  };

  const getConnectionState = () => {
    return connection.state;
  };

  return {
    connection,
    startConnection,
    disconnect,
    getConnectionState,
  };
};

export default createSignalRConnection;
