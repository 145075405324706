import { useConnectionSignalRStore } from "../stores/store";

const { default: createSignalRConnection } = require("../services/signalR");
const URL_SIGNALR = process.env.REACT_APP_URL_SIGNALR;

const InitSignalR = () => {
  const { connectionSignalR, setConnectionSignalR } = useConnectionSignalRStore((state) => state);

  const connect = async (hubUrl) => {
    if (!connectionSignalR) {
      const connection = createSignalRConnection(hubUrl);
      try {
        await connection.startConnection();
        
        setConnectionSignalR(connection);
        // console.log(`Connected to ${hubUrl}`);
      } catch (err) {
        console.error(`Error connecting to ${hubUrl}:`, err);
      }
    }
  };

  connect(URL_SIGNALR);
};
export default InitSignalR;
